.add-rule-container h4{
    font-size: 16px;
    color: #111827;
    font-weight: 600;
}
.add-rule-container p{
    font-size: 16px;
    font-weight: 400;
}
.add-rule-container h5{
    font-size: 16px;
    color: #6B7280;
    font-weight: 600;
}
.add-rule-container.add-new-rule h5{
    font-size: 16px;
    color: #111827;
    font-weight: 600;
}
.add-rule-container .rule-box{
    border: 1px #E5E7EB solid;
    background-color: #F9FAFB;
    border-radius: 8px;
    padding: 15px 20px;
    position: relative;
    cursor: pointer;
}
.add-rule-container .rule-box .box-header{
    color: #111827;
    font-size: 18px;
    font-weight: 600;
}
.add-rule-container .rule-box .box-header i{
    padding-right: 10px;
}
.add-rule-container .rule-box .box-body{
    padding-top: 15px;
    padding-left: 20px;
}
.add-rule-container .rule-box .box-body p{
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
}
.add-rule-container a{
    text-decoration: none;
}
.add-rule-container .rule-box .box-body select.form-select{
    width: 200px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    border-color: #D1D5DB;
}
.add-rule-container .rule-box .radio-icon{
    background-image: url("../../../assets/images/icons/radio-inactive.svg");
    background-repeat: no-repeat;
    padding: 10px;
    position: absolute;
    right: 8px;
    top: 8px;
}
.add-rule-container .rule-box:hover{
    border-color: #00D26E;
    background-color: #EAFFEC;
}
.add-rule-container .rule-box:hover .radio-icon{
    background-image: url("../../../assets/images/icons/radio-active.svg");
}

.add-rule-container.add-new-rule .form-control{
    height: 40px !important;
}

